import { css } from '@emotion/react';
import { theme } from 'src/theme';

const globalStyles = css`
  @font-face {
    font-family: 'Madera';
    src: url('/fonts/MaderaRegular.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Madera';
    src: url('/fonts/MaderaMedium.woff2') format('woff2');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Suisse Works';
    src: url('/fonts/SuisseWorks-Regular-WebS.woff2') format('woff2');
  }

  // overlow-x fox for windows
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    font-size: ${theme.fontSizes.xxsmall}px;
    overflow-x: hidden;
  }

  body {
    font-weight: 400;
    font-size: ${theme.fontSizes.medium}px;
    font-family: ${theme.fontFamily[0]};
  }

  a {
    color: inherit;
    text-decoration: none;
    line-height: 0;
  }

  p a {
    text-decoration: underline;
  }

  img {
    display: block;
  }
`;

export { globalStyles };
