import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@3pc/layout-components-react';

const ArrowRight = ({ ...props }) => {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width="55px"
      viewBox="0 0 55 18"
      height="18px"
      {...props}
    >
      <path
        d="M44.55 17.46l8.7-8.16v-.24L44.55.9l-1.11 1.2 5.64 5.22 1.38 1.17-2.13-.09H0v1.56h48.36l2.04-.09-1.41 1.11-5.55 5.28z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Box>
  );
};

ArrowRight.propTypes = {
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

export { ArrowRight };
