import React from 'react';
import { Box, Flex, Stack } from '@3pc/layout-components-react';
import { Text, Button } from 'src/elements';
import styled from '@emotion/styled';
import { getYoutubeConsent, youtubeOptIn, youtubeOptOut } from 'src/youtube';
import { NavHashLink } from 'react-router-hash-link';

const YoutubeVideo = ({ src }) => {
  const [consent, setConsent] = React.useState(getYoutubeConsent() === 'true');

  React.useEffect(() => {
    if (!consent) youtubeOptOut();
    else youtubeOptIn();
  }, [consent]);

  if (!src) {
    return null;
  }
  if (!src.includes('youtu')) {
    console.error(
      `Provided video source does not seem to be a YouTube link: ${src}`
    );
    return null;
  }
  if (!src.includes('embed')) {
    console.error(`Provided youtube link is not embedded: ${src}`);
    return null;
  }
  return !consent ? (
    <Flex justifyContent="center" width="100%">
      <Flex
        justifyContent="center"
        width={['100%', null, '495px', '680px']}
        backgroundColor="primary.0"
      >
        <Box
          width={['100%', null, '435px', '600px']}
          px={[5, null, 0]}
          py="5"
          color="white"
        >
          <Stack space="7">
            <Text>
              Hinweis: Wenn Sie die eingebetteten Youtube-Videos auf dieser
              Seite ansehen möchten, müssen Sie den{' '}
              <a href="https://www.youtube.com/t/terms">
                Nutzungsbedingungen von Youtube
              </a>{' '}
              zustimmen. Dadurch werden personenbezogene Daten (IP-Adresse) an
              den Betreiber des Videoportals gesendet. Daher ist es möglich,
              dass der Videoanbieter Ihre Zugriffe speichert und Ihr Verhalten
              analysieren kann.
            </Text>
            <Text>
              Sie können diese Zustimmung in unserem{' '}
              <NavHashLink to="/impressum#youtube-terms">Impressum</NavHashLink>{' '}
              widerrufen.
            </Text>
            <Button onClick={() => setConsent(true)}>
              <Text>
                Youtube Video ansehen und Nutzungsbedingungen zustimmen
              </Text>
            </Button>
          </Stack>
        </Box>
      </Flex>
    </Flex>
  ) : (
    <Box position="relative" height="0" width="100%" pb="56.25%">
      <IFrame
        src={src}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></IFrame>
    </Box>
  );
};

const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export default YoutubeVideo;
