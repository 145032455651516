import { ThemeTokens } from './ThemeTokens';

const theme: ThemeTokens = {
  breakpoints: ['576px', '768px', '1200px'],
  fontFamily: ['"Madera", sans-serif', '"Suisse Works", sans-serif'],
  // Get FontMetrics from here: https://seek-oss.github.io/capsize/
  fontMetrics: [
    {
      capHeight: 739,
      ascent: 1247,
      descent: -240,
      lineGap: 0,
      unitsPerEm: 1000,
    },
    {
      capHeight: 725,
      ascent: 986,
      descent: -311,
      lineGap: 0,
      unitsPerEm: 1000,
    },
  ],
  fontSizes: [16, 18, 20, 22, 24, 30, 40, 50],
  colors: {
    text: '#000',
    primary: ['#00002B', '#00002BB3', '#1C1CBE'],
    white: '#ffffff',
    black: '#000000',
  },
  radii: ['0px', '2px', '3px', '6px', '187.5px', '9999px'],
  space: {
    px: '1px',
    0: '0',
    1: '0.25rem',
    2: '0.5rem',
    3: '0.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
    12: '3rem',
    16: '4rem',
    20: '5rem',
    24: '6rem',
    28: '7rem',
    32: '8rem',
    36: '9rem',
    40: '10rem',
    48: '12rem',
    52: '13rem',
    56: '14rem',
    64: '16rem',
    80: '20rem',
    88: '22rem',
    92: '23rem',
    96: '24rem',
    128: '32rem',
  },
  easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
};

theme.fontSizes.xxsmall = theme.fontSizes[0]; // 16
theme.fontSizes.xsmall = theme.fontSizes[1]; // 18
theme.fontSizes.small = theme.fontSizes[2]; //  20
theme.fontSizes.medium = theme.fontSizes[3]; // 22
theme.fontSizes.large = theme.fontSizes[4]; // 24
theme.fontSizes.xlarge = theme.fontSizes[5]; // 30
theme.fontSizes.xxlarge = theme.fontSizes[6]; // 40
theme.fontSizes.xxxlarge = theme.fontSizes[7]; // 50

export { theme };
