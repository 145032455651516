import React from 'react';
import { Box, Flex } from '@3pc/layout-components-react';
import { Text, KnockoutText, Image } from 'src/elements';
import { Narrator, Quote, ButtonLink } from 'src/components';

const Home = () => {
  React.useEffect(() => {
    document.title = 'Neue Natur | Klassik Stiftung Weimar';
  }, []);
  return (
    <Box pb={['16', null, '24', '36']}>
      {/* seo h1 */}
      <Box position="absolute" color="transparent">
        <Text as="h1">Neue Natur</Text>
      </Box>
      <Box
        position="absolute"
        width={['120px', null, 'auto']}
        top={[5, null, 10, 16]}
        left={[5, null, 10, 16]}
        zIndex={2}
      >
        <Image
          width="100%"
          height="100%"
          src="/images/logo_ksw.svg"
          alt="Logo der Klassik Stiftung Weimar"
        />
      </Box>
      <Box
        position="fixed"
        height="100vh"
        width="100vw"
        zIndex={-1}
        backgroundColor="black"
      >
        <Image
          opacity={0.7}
          width="100%"
          height="100%"
          objectFit="cover"
          links={[
            '/images/home_576.jpg',
            '/images/home_768.jpg',
            '/images/home_1200.jpg',
            '/images/home_1920.jpg',
          ]}
          webpLinks={[
            '/images/home_576.webp',
            '/images/home_768.webp',
            '/images/home_1200.webp',
            '/images/home_1920.webp',
          ]}
          alt="Der Park an der Ilm"
        />
      </Box>
      <KnockoutText />
      <Flex flexDirection="column" alignItems="center">
        <Box color="white" mt={['16', null, '36']}>
          <Text
            as="h2"
            fontWeight="600"
            textAlign="center"
            textTransform="uppercase"
            fontSize={['small', null, 'xxlarge', 'xxxlarge']}
            capsize={false}
          >
            Ein Park für alle
          </Text>
        </Box>
        <Box
          mt={['9', null, '24', '36']}
          mx={['10', null, 0]}
          width={[null, null, '435px', '600px']}
          position="relative"
          color="white"
        >
          <Text
            fontSize={['small', null, null, 'large']}
            capsize={false}
            fontWeight={['400', null, '500']}
          >
            Der Park an der Ilm steht für die Verbindung von Mensch und Natur.
            Bis ins 20. Jahrhundert war er ein Ort der herzoglichen
            Repräsentation; ein Statement ist er noch heute: Der Mensch findet
            sich hier mit vielen Stimmungen wieder – ob in schaurigen Höhlen,
            auf verschlungenen Wegen oder bei ausgelassenen Festen. Die
            gestaltete Natur wirkt sich direkt auf sein Gemüt aus. Damals wie
            heute lädt der Park alle ein, sich auf diese Empfindungen
            einzulassen.
          </Text>
        </Box>
        <Quote
          mt={['12', null, '48']}
          mx={['5', null, 0]}
          width={[null, null, '435px', '600px']}
        >
          <Text fontIndex={1} size="medium" textAlign="center">
            „Natur! Wir sind von ihr umgeben und umschlungen […] Wir leben
            mitten in ihr und sind ihr fremde. Sie spricht unaufhörlich mit uns
            und verrät uns ihr Geheimnis nicht. Wir wirken ständig auf sie und
            haben doch keine Gewalt über sie….“
          </Text>
          <Text size="xsmall" textAlign="center">
            Georg Christoph Tobler, „TiefurterJournal“, 1783
          </Text>
        </Quote>
        <Narrator mt={['16', null, '36', '24']} variant="light">
          <Text
            fontSize={['xxsmall', null, null, 'small']}
            capsize={false}
            fontIndex={1}
          >
            Hi, ich bin Fritz, der Sohn von Charlotte von Stein. Komm und
            entdecke mit mir den Park!
          </Text>
        </Narrator>
        <ButtonLink mt={['16', null, '24', '36']} href="/map">
          <Text fontWeight="500">Park entdecken</Text>
        </ButtonLink>
      </Flex>
    </Box>
  );
};

export default Home;
