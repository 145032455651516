import React from 'react';

function useIntersect({
  ref,
  options = {},
  once = true,
}: {
  ref: React.RefObject<HTMLElement>;
  options?: IntersectionObserverInit;
  once?: boolean;
}) {
  const observerRef = React.useRef<IntersectionObserver>();
  const optionsRef = React.useRef<IntersectionObserverInit>(
    Object.assign(
      {
        rootMargin: '0px',
        threshold: 1.0,
      },
      options
    )
  );
  const [isIntersecting, setIntersecting] = React.useState<boolean>(false);

  const onIntersecting = React.useCallback(
    ([entry]) => {
      if (entry.isIntersecting) {
        setIntersecting(true);
        once && observerRef.current?.unobserve(entry.target);
      }
    },
    [once]
  );

  React.useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver(
      onIntersecting,
      optionsRef.current
    );

    if (ref.current) observerRef.current.observe(ref.current);

    return () => {
      observerRef.current?.disconnect();
    };
  }, [onIntersecting, ref]);

  return isIntersecting;
}

export default useIntersect;
