import React from 'react';
import { Box } from '@3pc/layout-components-react';
import { TextProps } from 'src/elements';

const TextWrapper = ({
  children,
  background = false,
  ...props
}: {
  children: React.ReactElement<TextProps>;
  background: boolean;
  [x: string]: any;
}) => {
  return (
    <Box
      position="relative"
      px={[7, null, 8, 10]}
      mx={[8, null, 0]}
      py={background ? [5, null, 8, 10] : 0}
      color={background ? 'white' : 'black'}
      width={[null, null, '499px', '680px']}
      {...props}
    >
      {children}
      {background && (
        <Box
          position="absolute"
          left="0"
          top="0"
          width="100%"
          height="100%"
          backgroundColor="black"
          opacity={0.6}
          zIndex={-1}
        />
      )}
    </Box>
  );
};

export default TextWrapper;
