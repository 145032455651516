import React from 'react';
import { useQuery } from '@apollo/client';
import CHAPTERS from 'src/graphql/Chapters.graphql';
import {
  Chapters_chapters,
  Chapters,
} from 'src/graphql/__generated__/Chapters';

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  if (value === null || value === undefined) return false;
  return true;
}

const Context = React.createContext<{ chapters: Chapters_chapters[] } | null>(
  null
);

const ChapterProvider = ({ children }) => {
  const { data } = useQuery<Chapters>(CHAPTERS);
  const [chapters, setChapters] = React.useState<Chapters_chapters[]>([]);

  React.useEffect(() => {
    if (!data?.chapters) return;
    setChapters(data.chapters.filter(notEmpty));
  }, [data?.chapters]);

  return <Context.Provider value={{ chapters }}>{children}</Context.Provider>;
};

const useChapterState = () => {
  const context = React.useContext(Context);
  if (context === null) {
    throw new Error('useChapterState must be used within a ChapterProvider');
  }
  return context;
};

export { ChapterProvider, useChapterState };
