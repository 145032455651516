import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@3pc/layout-components-react';

const Plus = ({ ...props }) => {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width="22px"
      viewBox="0 0 22 22"
      height="22px"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-682-2917H86v9254h-768z" />
        <path
          d="M9.81 0v9.81H0v2.38h9.81V22h2.38v-9.81H22V9.81h-9.81V0H9.81z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </Box>
  );
};

Plus.propTypes = {
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

export { Plus };
