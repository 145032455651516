import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@3pc/layout-components-react';

const ArrowDiagonal = ({ ...props }) => {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width="18px"
      height="16px"
      viewBox="0 0 1024 1024"
      fill="currentColor"
      {...props}
    >
      <path d="M131.807 28.181L1003.803.252l12.413 12.413-27.929 871.996-119.473-4.655 21.722-561.677 10.861-131.886-105.508 114.818-692.011 692.011-80.683-80.683 693.563-693.563 110.163-100.854-130.334 15.516-560.126 13.964z" />
    </Box>
  );
};

ArrowDiagonal.propTypes = {
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

export { ArrowDiagonal };
