import React from 'react';
import { Box, Flex } from '@3pc/layout-components-react';
import { Text, Link, Image } from 'src/elements';
import styled from '@emotion/styled';
import pointData from './points.json';
import { Chapters_chapters } from 'src/graphql/__generated__/Chapters';
import { useChapterState } from 'src/context/chapterContext';

const Map = () => {
  const { chapters } = useChapterState();

  React.useEffect(() => {
    document.title = 'Karte | Klassik Stiftung Weimar';
  }, []);

  return (
    <Box position="relative" minHeight="100vh" backgroundColor="primary.0">
      <Box
        position="absolute"
        left="0"
        top="0"
        width="100%"
        height="100%"
        backgroundColor="primary.0"
      ></Box>
      <Box
        position="absolute"
        top="0"
        left="0"
        pt="10"
        color="white"
        width="100%"
        zIndex={1}
      >
        <Text
          as="h1"
          textAlign="center"
          textTransform="uppercase"
          fontWeight="600"
        >
          Park an der Ilm
        </Text>
      </Box>
      <Flex minHeight="100vh" height="100%" width="100%" alignItems="center">
        <Box position="relative" backgroundColor="black" width="100%">
          <Image
            width="100%"
            links={[
              '/images/map_576.jpg',
              '/images/map_768.jpg',
              '/images/map_1200.jpg',
              '/images/map_1920.jpg',
            ]}
            webpLinks={[
              '/images/map_576.webp',
              '/images/map_768.webp',
              '/images/map_1200.webp',
              '/images/map_1920.webp',
            ]}
            alt="Karte des Parks"
            opacity={0.8}
          />
          <Box position="absolute" left="0" top="0" width="100%" height="100%">
            <Box
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
            >
              {pointData.points.map(point => (
                <Point
                  x={point.position.x}
                  y={point.position.y}
                  chapter={chapters.find(c => c && c.id === point.chapterId)}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

const Point = ({
  chapter,
  x,
  y,
}: {
  chapter?: Chapters_chapters | null;
  x: string[];
  y: string[];
}) => {
  if (!chapter) return null;
  return (
    <TransformBox position="absolute" top={y} left={x}>
      <Link href={`preview/${chapter.slug}`}>
        <Flex alignItems="center" flexDirection="column">
          <Box
            position="relative"
            width={['40px', null, '64px']}
            height={['40px', null, '64px']}
            borderStyle="solid"
            borderWidth={['4px', null, '8px']}
            borderColor="white"
            borderRadius="5"
          >
            <TransformBox
              position="absolute"
              top="50%"
              left="50%"
              width="21%"
              height="21%"
              backgroundColor="white"
              borderRadius="5"
            />
          </Box>
          <TitleBox
            position="absolute"
            mt="2"
            top="100%"
            left="50%"
            maxWidth={['100px', 'none']}
          >
            <Text
              textAlign="center"
              fontSize={['xxsmall', null, 'small']}
              color="white"
              capsize={false}
              lineHeight={1}
              fontWeight="500"
              whiteSpace="nowrap"
            >
              {chapter.title}
            </Text>
          </TitleBox>
        </Flex>
      </Link>
    </TransformBox>
  );
};

const TransformBox = styled(Box)`
  transform: translate(-50%, -50%);
`;

const TitleBox = styled(Box)`
  transform: translateX(-50%);

  @media (min-width: ${p => p.theme.breakpoints[1]}) {
    white-space: nowrap;
  }
`;

export default Map;
