/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AssetType {
  AUDIO = "AUDIO",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum ScreenAlignment {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
