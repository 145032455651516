import React from 'react';
import { theme } from 'src/theme/theme';
import styled from '@emotion/styled';
import useIntersect from 'src/hooks/useIntersect';

type ImageType = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  opacity?: number;
  links?: (string | null)[];
  webpLinks?: [string, string, string, string];
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  alt: string;
  maxHeight?: string;
  minHeight?: string;
  maxWidth?: string;
  webpSrc?: string;
  filter?: string;
};

const supportsLazyLoading = 'loading' in HTMLImageElement.prototype;

const LazyImage = ({
  src,
  webpSrc,
  opacity,
  links,
  webpLinks,
  alt,
  ...props
}: ImageType) => {
  return supportsLazyLoading ? (
    <Image
      src={src}
      webpSrc={webpSrc}
      opacity={opacity}
      links={links}
      webpLinks={webpLinks}
      alt={alt}
      loading="lazy"
      {...props}
      ref={undefined}
    />
  ) : (
    <LegacyLazyImage
      src={src}
      webpSrc={webpSrc}
      opacity={opacity}
      links={links}
      webpLinks={webpLinks}
      alt={alt}
      {...props}
    />
  );
};

const Image = React.forwardRef<HTMLImageElement, ImageType>(
  ({ src, webpSrc, opacity, links, webpLinks, alt, filter, ...props }, ref) => {
    if (!links && webpSrc) {
      return (
        <picture>
          <source type="image/webp" srcSet={webpSrc} />
          <Img ref={ref} src={src} alt={alt} {...props} />
        </picture>
      );
    }
    if (!links) return <Img ref={ref} src={src} alt={alt} {...props} />;
    return (
      <picture>
        {webpLinks && <WebpSources webpLinks={webpLinks} />}
        <source
          media={`(min-width: ${theme.breakpoints[2]})`}
          srcSet={links[3] ? links[3] : ''}
        />
        <source
          media={`(min-width: ${theme.breakpoints[1]})`}
          srcSet={links[2] ? links[2] : ''}
        />
        <source
          media={`(min-width: ${theme.breakpoints[0]})`}
          srcSet={links[1] ? links[1] : ''}
        />
        <Img
          ref={ref}
          src={links[0] ? links[0] : ''}
          alt={alt}
          filter={filter}
          opacity={opacity}
          {...props}
        />
      </picture>
    );
  }
);

const WebpSources = ({
  webpLinks,
}: {
  webpLinks: [string, string, string, string];
}) => {
  return (
    <>
      <source
        type="image/webp"
        media={`(max-width: ${theme.breakpoints[0]})`}
        srcSet={webpLinks[0]}
      />
      <source
        type="image/webp"
        media={`(max-width: ${theme.breakpoints[1]})`}
        srcSet={webpLinks[1]}
      />
      <source
        type="image/webp"
        media={`(max-width: ${theme.breakpoints[2]})`}
        srcSet={webpLinks[2]}
      />
      <source
        type="image/webp"
        media={`(min-width: ${theme.breakpoints[2]})`}
        srcSet={webpLinks[3]}
      />
    </>
  );
};

const Img = styled.img<ImageType>`
  object-fit: ${p => p.objectFit && p.objectFit};
  width: ${p => p.width && p.width};
  height: ${p => p.height && p.height};
  max-height: ${p => p.maxHeight && p.maxHeight};
  min-height: ${p => p.minHeight && p.minHeight};
  max-width: ${p => p.maxWidth && p.maxWidth};
  opacity: ${p => p.opacity && p.opacity};
  filter: ${p => p.filter && p.filter};
`;

const LegacyLazyImage = ({
  src,
  webpSrc,
  opacity,
  links,
  webpLinks,
  alt,
  ...props
}: ImageType) => {
  const ref = React.useRef<HTMLImageElement | null>(null);
  const isIntersecting = useIntersect({ ref, options: { rootMargin: '80px' } });
  const [load, setLoad] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!isIntersecting) return;
    setLoad(true);
  }, [isIntersecting]);
  return (
    <Image
      src={load ? src : ''}
      webpSrc={load ? webpSrc : undefined}
      opacity={opacity}
      links={links}
      webpLinks={load ? webpLinks : undefined}
      alt={alt}
      {...props}
      ref={ref}
    />
  );
};

export { Image, LazyImage };
