import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@3pc/layout-components-react';

const ArrowDown = ({ ...props }) => {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width="17px"
      height="54px"
      {...props}
    >
      <path
        d="M.345 45.105l8.16 8.7h.24l8.16-8.7-1.2-1.11-5.22 5.64-1.17 1.38.09-2.13V.555h-1.56v48.36l.09 2.04-1.11-1.41-5.28-5.55z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Box>
  );
};

ArrowDown.propTypes = {
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

export { ArrowDown };
