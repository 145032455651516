import React from 'react';
import { Box } from '@3pc/layout-components-react';
import { TextProps } from 'src/elements';

interface QuoteInterface {
  children: [React.ReactElement<TextProps>, React.ReactElement<TextProps>];
  [x: string]: any;
}

const Quote: React.FC<QuoteInterface> = ({ children, ...props }) => {
  return (
    <Box
      py={['8', null, '12']}
      backgroundColor="primary.0"
      color="white"
      {...props}
    >
      <Box mx={['5', null, '12']}>{children[0]}</Box>
      <Box mt={['5', null, null, '10']} mx={['9', null, '12', '36']}>
        {children[1]}
      </Box>
    </Box>
  );
};

export { Quote };
