import React from 'react';
import styled from '@emotion/styled';

const KnockoutText = () => {
  const [mask, setMask] = React.useState('none');

  const onload = () => {
    setMask('url(#mask)');
  };
  //fix chrome render bug where it doesn't show masked text on intial render
  React.useEffect(() => {
    if (document.readyState === 'complete') {
      onload();
    }
    window.addEventListener('load', onload);
    return () => window.removeEventListener('load', onload);
  }, []);
  return (
    <SVG>
      <defs>
        <mask id="mask" x="0" y="0">
          <OverlayRect width="100%" height="100%" />
          <Text y="0" dy="50%">
            <tspan x="50%" y="-0.6em" textAnchor="middle">
              NEUE
            </tspan>
            <tspan x="50%" textAnchor="middle" dy="1.2em">
              NATUR
            </tspan>
          </Text>
        </mask>
      </defs>
      <BackgroundRect
        id="r"
        x="0"
        y="0"
        width="100%"
        height="100%"
        mask={mask}
      />
    </SVG>
  );
};

const SVG = styled.svg`
  display: block;
  width: 100vw;
  height: 100vh;
  text-align: center;
`;

const OverlayRect = styled.rect`
  fill: ${p => p.theme.colors.white};
`;

const BackgroundRect = styled.rect`
  fill: ${p => p.theme.colors.primary[0]};
  mask: ${p => (p.mask ? p.mask : 'url(#mask)')};
`;

const Text = styled.text`
  color: transparent;
  font-size: 90px;
  font-weight: 600;

  @media (min-width: ${p => p.theme.breakpoints[1]}) {
    font-size: 170px;
  }
`;

export { KnockoutText };
