import React from 'react';
import { ApolloProvider } from '@apollo/client';
import client from './client';
import { ChapterProvider } from 'src/context/chapterContext';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import { LayoutProvider } from '@3pc/layout-components-react';
import Home from 'src/screens/home/Home';
import Map from 'src/screens/map/Map';
import Chapter from 'src/screens/chapter/Chapter';
import ChapterPreview from 'src/screens/chapter/ChapterPreview';
import Imprint from 'src/screens/imprint/';
import { Menu, CookieBanner } from 'src/components';
import { theme } from './theme/theme';
import { globalStyles } from './global-styles';

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const PageNotFound = () => <p>Not found</p>;

const App = () => {
  return (
    <ApolloProvider client={client}>
      <ChapterProvider>
        <Router>
          <ScrollToTop />
          <LayoutProvider
            theme={theme}
            hideFocusRings={false}
            styles={globalStyles}
          >
            <CookieBanner />
            <Menu />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/map" element={<Map />} />
              <Route path="/preview/:slug" element={<ChapterPreview />} />
              <Route path="/chapter/:slug" element={<Chapter />} />
              <Route path="/impressum" element={<Imprint />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </LayoutProvider>
        </Router>
      </ChapterProvider>
    </ApolloProvider>
  );
};

export default App;
