import { theme } from 'src/theme/theme';
// import capsize from './capsize';
import capsize from 'capsize';

export type TextSizeProps =
  | 'xxxlarge'
  | 'xxlarge'
  | 'xlarge'
  | 'large'
  | 'medium'
  | 'small'
  | 'xsmall'
  | 'xxsmall';

const useText = (size: TextSizeProps, crop = true, fontIndex = 0) => {
  const { fontSizes, fontMetrics } = theme;

  const fontSize =
    size === 'xxxlarge'
      ? fontSizes.xxxlarge
      : size === 'xxlarge'
      ? fontSizes.xxlarge
      : size === 'xlarge'
      ? fontSizes.xlarge
      : size === 'large'
      ? fontSizes.large
      : size === 'medium'
      ? fontSizes.medium
      : size === 'small'
      ? fontSizes.small
      : size === 'xsmall'
      ? fontSizes.xsmall
      : fontSizes.xxsmall;

  return crop
    ? capsize({
        fontSize,
        fontMetrics: fontMetrics[fontIndex],
        leading: fontSize * (fontIndex === 0 ? 1.5 : 1.3),
      })
    : {
        fontSize,
        padding: 0,
      };
};

export default useText;
