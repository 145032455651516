import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@3pc/layout-components-react';

const CollapsibleButton = ({
  children,
  open,
  setOpen,
  panelId,
  ...props
}: CollapsibleButtonProps) => {
  function handleClick(event: React.MouseEvent) {
    event.preventDefault();
    setOpen(wasOpen => !wasOpen);
  }

  return (
    <ButtonBox
      as="button"
      onClick={handleClick}
      aria-controls={panelId}
      aria-expanded={open}
      {...props}
    >
      {children}
    </ButtonBox>
  );
};

export type CollapsibleButtonProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  panelId: string;
  children: React.ReactNode;
  [x: string]: any;
};

const CollapsiblePanel = ({
  children,
  open,
  id,
  ...props
}: CollapsiblePanelProps) => {
  return (
    <Box
      hidden={!open}
      display={open ? 'block' : 'none'}
      tabIndex={-1}
      id={id}
      {...props}
    >
      {children}
    </Box>
  );
};

export type CollapsiblePanelProps = {
  /**
   * Inner collapsible content for the accordion item.
   */
  children: React.ReactNode;
  open: boolean;
  id: string;
  [x: string]: any;
};

const ButtonBox = styled(Box)`
  display: inline-flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  line-height: 1;
  font-size: inherit;
  border: 0;
  outline: none;
  border-radius: ${p => p.theme.radii[1]};
  background: transparent;
  transition-property: background;
  transition-duration: 120ms;
  transition-timing-function: ${p => p.theme.easing};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: ${p => p.theme.colors.primary[0]};
    color: white;
  }
`;

export { CollapsibleButton, CollapsiblePanel };
