import React from 'react';
import { Box, Flex } from '@3pc/layout-components-react';
import { Text, LazyImage } from 'src/elements';
import Content from './content/Content';
import {
  Chapter_chapter_subStories,
  Chapter_chapter_subStories_backgroundImage,
} from 'src/graphql/__generated__/Chapter';
import styled from '@emotion/styled';

const Substory = ({
  substory,
  ...props
}: {
  substory: Chapter_chapter_subStories;
  [x: string]: any;
}) => {
  const image = substory.backgroundImage;
  return (
    <Box position="relative" zIndex={1} {...props}>
      <IntroBox image={image}>
        <Box
          id={`substory-${substory.id}`}
          px={[7, null, null, 28]}
          pt={['40', null, '48', '64']}
        >
          <Text
            as="h2"
            color={image ? 'white' : 'primary.0'}
            textAlign="center"
            textTransform="uppercase"
            size="xlarge"
            fontWeight="600"
            wordWrap="break-word"
          >
            {substory.title}
          </Text>
        </Box>
        {substory.introText && (
          <Flex flexDirection="column" alignItems="center" mt={[20, null, 28]}>
            <Box
              px={[10, null, 0]}
              width={['100%', null, '435px', '600px']}
              color={image ? 'white' : 'black'}
            >
              <Text size="medium">{substory.introText}</Text>
            </Box>
          </Flex>
        )}
      </IntroBox>
      <Box
        borderRadius={[
          '0 0 50vw 50vw',
          '0 0 84px 84px',
          null,
          '0 0 210px 210px',
        ]}
        pb={[64, 36, null, 52]}
        backgroundColor="white"
      >
        {substory.contentElements.map(
          content => content && <Content key={content.id} content={content} />
        )}
      </Box>
    </Box>
  );
};

const IntroBox = ({
  image,
  children,
  ...props
}: {
  image?: Chapter_chapter_subStories_backgroundImage | null;
  children: React.ReactNode;
  [x: string]: any;
}) => {
  return (
    <Flex
      alignItems="flex-start"
      borderRadius={['50vw 50vw 0 0', null, null, '510px 510px 0 0']}
      {...props}
    >
      <Box width="100%" flex="1 0 100%" pb={image ? '100vh' : '5'}>
        {children}
      </Box>
      {image && image.links[0] && (
        <TransformBox
          position="sticky"
          height="100vh"
          width="100%"
          top="0"
          flex="1 0 100%"
          borderRadius={['50vw 50vw 0 0', null, null, '510px 510px 0 0']}
          overflow="hidden"
          zIndex={-1}
          backgroundColor="black"
          transform="translateX(-100%)"
        >
          <LazyImage
            links={image.links}
            alt={image.description.shortAbstract || ''}
            width="100%"
            minHeight="100%"
            objectFit="cover"
            opacity={0.7}
          />
        </TransformBox>
      )}
    </Flex>
  );
};

type TransformBoxProps = {
  transform?: string;
};

const TransformBox = styled(Box)<TransformBoxProps>`
  transform: ${p => p.transform && p.transform};
`;

export default Substory;
