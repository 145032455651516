export function optIn() {
  window._paq && window._paq.push(['forgetUserOptOut']);
  window.localStorage.setItem('matomoOptOut', 'false');
}

export function optOut() {
  window._paq && window._paq.push(['optUserOut']);
  window.localStorage.setItem('matomoOptOut', 'true');
}

export function getOptOut() {
  return window.localStorage.getItem('matomoOptOut');
}
