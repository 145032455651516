import React from 'react';
import { Box, Flex } from '@3pc/layout-components-react';
import { Link } from 'src/elements';
import styled from '@emotion/styled';

type ButtonLinkType = {
  href: string;
  children: React.ReactNode;
  [x: string]: any;
};

const ButtonLink = ({ href, children, ...props }: ButtonLinkType) => {
  return (
    <Box {...props}>
      <Link href={href}>
        <HoverFlex
          px="10"
          py="10"
          alignItems="center"
          justifyContent="center"
          color="white"
          border="2px solid"
          borderColor="white"
          borderRadius="50px"
        >
          {children}
        </HoverFlex>
      </Link>
    </Box>
  );
};

const HoverFlex = styled(Flex)`
  &:hover {
    background-color: white;
    color: ${p => p.theme.colors.primary[0]};
  }
`;

export { ButtonLink };
