import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { __isDev__ } from './utils/environment';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// NOTE: fixes hmr in dev mode
// @ts-ignore
if (__isDev__ && module.hot) {
  // @ts-ignore
  module.hot.accept();
}
