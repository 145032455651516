import React from 'react';
import {
  SubstoryFields_contentElements_asset as AssetProps,
  SubstoryFields_contentElements_objectElement as ObjectElement,
} from 'src/graphql/__generated__/SubstoryFields';
import { AssetType } from 'src/graphql/__generated__/globalTypes';
import YoutubeVideo from './YoutubeVideo';
import { Box, Flex } from '@3pc/layout-components-react';
import { LazyImage, Text } from 'src/elements';

const Asset = ({
  asset,
  objectElement,
}: {
  asset: AssetProps;
  objectElement: ObjectElement | null;
}) => {
  const title = objectElement?.description.title || asset.description.title;
  const creator = objectElement?.creator;
  return (
    <Box width="100%">
      <Flex justifyContent="center" width="100%">
        {asset.type === AssetType.IMAGE ? (
          <Box px={[5, null, 12]}>
            <LazyImage
              maxHeight="100vh"
              maxWidth="100%"
              links={asset?.links}
              alt={asset.description.shortAbstract || ''}
              objectFit="cover"
            />
          </Box>
        ) : asset.type === AssetType.VIDEO ? (
          <Box width="100%" px={[null, null, 12, 48]}>
            <YoutubeVideo src={asset.links[0]} />
          </Box>
        ) : null}
      </Flex>

      {(title || creator) && (
        <Flex justifyContent="center" width="100%">
          <Box
            mt={['3', null, null, '7']}
            width={['100%', null, '435px', '600px']}
            px={[10, null, 0]}
          >
            {title && (
              <Text
                fontWeight="600"
                fontSize={['xxsmall', null, null, 'small']}
                capsize={false}
              >
                {title}
              </Text>
            )}
            {creator && (
              <Text
                capsize={false}
                fontSize={['xxsmall', null, null, 'small']}
                whiteSpace="pre-line"
              >
                {creator}
              </Text>
            )}
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default Asset;
