import React from 'react';
import { Flex, Box } from '@3pc/layout-components-react';
import { Text, LazyImage } from 'src/elements';
import { Narrator, Quote } from 'src/components';
import { Chapter_chapter_subStories_contentElements as ContentType } from 'src/graphql/__generated__/Chapter';
import styled from '@emotion/styled';
import TextWrapper from './TextWrapper';
import Asset from './Asset';
import AssetInformation from './AssetInformation';

const Content = ({ content }: { content: ContentType }) => {
  return (
    <Flex
      alignItems="flex-start"
      className="content"
      mt={content.backgroundImage ? '20' : 0}
    >
      <Flex
        position="relative"
        zIndex={1}
        flexDirection="column"
        alignItems="center"
        flex="1 0 100%"
        pb={content.backgroundImage ? '100vh' : 0}
      >
        {content.title && (
          <TextWrapper
            mt={[20, null, 24, 40]}
            background={!!content.backgroundImage}
          >
            <Text
              as="h3"
              fontSize={['xxsmall', null, null, 'small']}
              capsize={false}
              fontWeight="600"
            >
              {content.title}
            </Text>
          </TextWrapper>
        )}
        {content.text && (
          <TextWrapper
            mt={content.title ? [12, null, 20, 24] : [20, null, 24, 40]}
            background={!!content.backgroundImage}
          >
            <Text fontSize={['xxsmall', null, null, 'small']} capsize={false}>
              {content.text}
            </Text>
          </TextWrapper>
        )}
        {content.asset && (
          <Box mt={[20, null, 24, 40]} width="100%">
            <Flex flexDirection="column" alignItems="center">
              <Asset
                asset={content.asset}
                objectElement={content.objectElement}
              />

              {content.objectElement && (
                <AssetInformation
                  historicalObject={content.objectElement}
                  mt={[3, null, null, 4]}
                />
              )}
            </Flex>
          </Box>
        )}
        {content.fritz && (
          <Narrator align={content.fritz.alignment} mt={[20, null, 36]}>
            <Text
              fontSize={['xxsmall', null, null, 'small']}
              capsize={false}
              fontIndex={1}
            >
              {content.fritz.text}
            </Text>
          </Narrator>
        )}
        {content.quote && (
          <Quote
            mt={[20, null, 24, 40]}
            mx={['5', null, 0]}
            width={[null, null, '435px', '600px']}
          >
            <Text fontIndex={1} size="medium" textAlign="center">
              {content.quote.text}
            </Text>
            <Text size="xsmall" textAlign="center">
              {content.quote.author || content.quote.caption}
            </Text>
          </Quote>
        )}
      </Flex>
      {content.backgroundImage && (
        <TransformBox
          position="sticky"
          height="100vh"
          width="100%"
          top="0"
          flex="1 0 100%"
          transform="translateX(-100%)"
        >
          <LazyImage
            links={content.backgroundImage.links}
            alt={content.backgroundImage.description.shortAbstract || ''}
            objectFit="cover"
            width="100%"
            height="100%"
          />
        </TransformBox>
      )}
    </Flex>
  );
};

type TransformBoxProps = {
  transform?: string;
};

const TransformBox = styled(Box)<TransformBoxProps>`
  transform: ${p => p.transform && p.transform};
`;

export default Content;
