import React, { AllHTMLAttributes } from 'react';
import styled from '@emotion/styled';

const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  children,
  ...props
}) =>
  variant === 'primary' ? (
    <StyledButton type="button" {...props}>
      {children}
    </StyledButton>
  ) : (
    <PlainButton type="button" {...props}>
      {children}
    </PlainButton>
  );

export type BasicButtonProps = Omit<
  AllHTMLAttributes<HTMLButtonElement>,
  'size' | 'as'
> & {
  type?: 'button' | 'reset' | 'submit';
  variant?: 'primary' | 'plain';
  onClick?: (event: React.SyntheticEvent<Element, Event>) => void;
  children: React.ReactNode;
};

export type ButtonProps = BasicButtonProps & {
  as?: As;
};

type As<P = any> = React.ElementType<P>;

const StyledButton = styled.button<ButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: ${p => p.theme.space[10]} ${p => p.theme.space[3]};
  border: 2px solid ${p => p.theme.colors.white};
  border-radius: 50px;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  outline: none;
  background-color: transparent;

  &:hover {
    background-color: white;
    color: ${p => p.theme.colors.primary[0]};
  }

  &:active {
  }

  &[disabled] {
    cursor: default;
    pointer-events: none;
  }
`;

const PlainButton = styled.button<ButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  outline: none;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  width: auto;
  overflow: visible;
  color: inherit;
  font-family: inherit;
  line-height: normal;
  text-align: inherit;
  border-radius: 0;
  padding: 0;

  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
`;

Button.displayName = 'Button';
export { Button, StyledButton };
