
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ChapterFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Chapter"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"subTitle"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"introText"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"previewText"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"image"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"copyRight"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"links"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"shortAbstract"},"arguments":[],"directives":[]}]}}]}}]}}],"loc":{"start":0,"end":184}};
    doc.loc.source = {"body":"fragment ChapterFields on Chapter {\n  title\n  subTitle\n  introText\n  previewText\n  image {\n    id\n    copyRight\n    links\n    description {\n      title\n      shortAbstract\n    }\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
