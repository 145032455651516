import React from 'react';
import { Box, Stack, Flex } from '@3pc/layout-components-react';
import { Button, Text } from 'src/elements';
import styled from '@emotion/styled';
import { NavHashLink } from 'react-router-hash-link';
import { useChapterState } from 'src/context/chapterContext';

const Menu = () => {
  const [isOpen, setOpen] = React.useState(false);
  const { chapters } = useChapterState();
  const [hoveredChapter, setHoveredChapter] = React.useState('');

  React.useEffect(() => {
    if (isOpen) document.documentElement.style.overflow = 'hidden';
    else {
      document.documentElement.style.overflow = '';
      document.documentElement.style.overflowX = 'hidden';
    }
  }, [isOpen]);
  return (
    <>
      <Box
        position="fixed"
        top={[5, null, 10]}
        right={[5, null, 10]}
        zIndex={4}
        width={['42px', null, '60px']}
        height={['42px', null, '60px']}
      >
        <Button variant="plain" onClick={() => setOpen(wasOpen => !wasOpen)}>
          <Flex
            position="relative"
            justifyContent="center"
            alignItems="center"
            width={['42px', null, '60px']}
            height={['42px', null, '60px']}
          >
            <Box
              position="absolute"
              top="0"
              left="0"
              height="100%"
              width="100%"
              borderRadius="5"
              backgroundColor="white"
              opacity="0.5"
            />
            <Box
              position="relative"
              height={['17px', null, '28px']}
              width={['24px', null, '40px']}
            >
              <Line isOpen={isOpen} />
              <Line isOpen={isOpen} />
              <Line isOpen={isOpen} />
            </Box>
          </Flex>
        </Button>
      </Box>
      <Overlay isOpen={isOpen}>
        <Box backgroundColor="primary.0">
          <Flex
            alignItems="center"
            pl={['10', null, '16', '80']}
            pt={['20', null, null, '28']}
            pb="16"
            pr={['10', null, '20', '80']}
            justifyContent="space-between"
            overflowY="auto"
            maxHeight="100vh"
          >
            <Box pr="10">
              <Stack space="8">
                <Link href="/" onClick={() => setOpen(false)}>
                  <Text size="small" textTransform="uppercase">
                    Home
                  </Text>
                </Link>
                <Link href="/map" onClick={() => setOpen(false)}>
                  <Text size="small" textTransform="uppercase">
                    Karte
                  </Text>
                </Link>
                {chapters.map(chapter => (
                  <Box
                    key={chapter.id}
                    onMouseEnter={() => setHoveredChapter(chapter.id)}
                    onMouseLeave={() => setHoveredChapter('')}
                  >
                    <Stack space="5">
                      <Link
                        href={`/chapter/${chapter.slug}`}
                        onClick={() => setOpen(false)}
                      >
                        <Text size="small" textTransform="uppercase">
                          {chapter.title}
                        </Text>
                      </Link>
                      {chapter.subStories && (
                        <Stack space="4">
                          {chapter.subStories.map(substory =>
                            substory ? (
                              <Link
                                key={substory.id}
                                href={`/chapter/${chapter.slug}#substory-${substory.id}`}
                                highlightCurrent={false}
                                onClick={() => setOpen(false)}
                              >
                                <Text size="xsmall">{substory.title}</Text>
                              </Link>
                            ) : null
                          )}
                        </Stack>
                      )}
                    </Stack>
                  </Box>
                ))}
                <Link href="/impressum" onClick={() => setOpen(false)}>
                  <Text size="small" textTransform="uppercase">
                    Impressum
                  </Text>
                </Link>
              </Stack>
            </Box>
            {chapters && (
              <Box
                position="relative"
                display={['none', null, 'block']}
                width="300px"
                height="300px"
                borderRadius="5"
                overflow="hidden"
              >
                {chapters.map(
                  chapter =>
                    chapter.image && (
                      <ImageWrapper
                        key={chapter.id}
                        position="absolute"
                        width="100%"
                        height="100%"
                        left="0"
                        top="0"
                        opacity={hoveredChapter === chapter.id ? 1 : 0}
                      >
                        <Image
                          src={chapter.image.links[0] || ''}
                          alt={chapter.image.description.shortAbstract || ''}
                        />
                      </ImageWrapper>
                    )
                )}
              </Box>
            )}
          </Flex>
        </Box>
        <Box height="100%" onClick={() => setOpen(false)} />
      </Overlay>
    </>
  );
};

/* TODO: NavHashLink/react-router-dom v6 does not seem to work with the exact path match of the hash yet. */
/* TODO: Warning: React does not recognize the `isActive` prop on a DOM element */
const Link = ({
  href,
  highlightCurrent = true,
  onClick,
  children,
  ...props
}: {
  href: string;
  onClick?: any;
  children: React.ReactNode;
  highlightCurrent?: boolean;
  [x: string]: any;
}) => {
  return (
    <Box {...props} lineHeight="0">
      <NavLink to={href} end onClick={onClick}>
        <Box
          display="inline-flex"
          alignItems="center"
          className={highlightCurrent ? 'inner-link' : ''}
        >
          {children}
        </Box>
      </NavLink>
    </Box>
  );
};

// "active" class is automatically added by react-router-dom NavLink
const NavLink = styled(NavHashLink)`
  display: inline-block;
  &.active .inner-link:before {
    content: '';
    display: block;
    width: 10px;
    flex-shrink: 0;
    height: 10px;
    background-color: ${p => p.theme.colors.white};
    border-radius: ${p => p.theme.radii[5]};
    margin-right: ${p => p.theme.space[2]};
  }
`;

type LineProps = {
  isOpen?: boolean;
};

type OverlayProps = {
  isOpen?: boolean;
};

const Overlay = styled(Box)<OverlayProps>`
  position: fixed;
  width: 100vw;
  transform: ${p => (p.isOpen ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.25s ${p => p.theme.easing};
  color: ${p => p.theme.colors.white};
  /* background-color: ${p => p.theme.colors.primary[0]}; */
  height: 100vh;
  z-index: 3;
`;

const Line = styled(Box)<LineProps>`
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: ${p => p.theme.colors.black};
  transform-origin: left center;
  transition: transform 0.25s ${p => p.theme.easing};

  &:nth-of-type(1) {
    transform: ${p =>
      p.isOpen ? 'rotate(45deg) translate(1.5px, -3.3px)' : ''};
  }

  &:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
    transform-origin: center center;
    opacity: ${p => (p.isOpen ? 0 : 1)};
  }

  &:nth-of-type(3) {
    bottom: 0;
    transform: ${p => (p.isOpen ? 'rotate(-45deg) translate(2px, 2.7px)' : '')};
  }

  @media (min-width: ${p => p.theme.breakpoints[1]}) {
    &:nth-of-type(1) {
      transform: ${p =>
        p.isOpen ? 'rotate(45deg) translate(4px, -4.5px)' : ''};
    }

    &:nth-of-type(3) {
      transform: ${p =>
        p.isOpen ? 'rotate(-45deg) translate(2px, 4.7px)' : ''};
    }
  }
`;

const ImageWrapper = styled(Box)`
  transform: scale(1.5);
  transition: opacity 0.4s ${p => p.theme.easing};
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export { Menu };
