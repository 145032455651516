import React from 'react';
import { Flex, Box } from '@3pc/layout-components-react';
import styled from '@emotion/styled';
import { ScreenAlignment as Align } from 'src/graphql/__generated__/globalTypes';
import useIntersect from 'src/hooks/useIntersect';
import { LazyImage } from 'src/elements';

type NarratorType = {
  align?: Align;
  variant?: 'dark' | 'light';
  children: React.ReactNode;
  [x: string]: any;
};

const Narrator = ({
  align = Align.LEFT,
  variant = 'dark',
  children,
  ...props
}: NarratorType) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const isIntersecting = useIntersect({ ref, options: { threshold: 0.7 } });

  return (
    <Flex
      position="relative"
      width="100vw"
      overflowX="hidden"
      justifyContent={align === Align.LEFT ? 'flex-start' : 'flex-end'}
      {...props}
    >
      <Box
        ref={ref}
        position="absolute"
        left="0"
        top="0"
        width="100%"
        height="100%"
      />
      <TransformFlex
        width={[0.975, 0.9, 0.75, 0.6]}
        alignItems="flex-end"
        justifyContent={align === Align.LEFT ? 'flex-start' : 'flex-end'}
        minHeight={['200px', null, '300px']}
        transform={
          !isIntersecting
            ? align === Align.LEFT
              ? 'translateX(-100%)'
              : 'translateX(100%)'
            : undefined
        }
      >
        <ImageWrapper
          position="absolute"
          flexShrink={0}
          flip={align === Align.RIGHT}
          width={['200px', null, '300px']}
          height={['200px', null, '300px']}
          left={align === Align.LEFT ? '0' : null}
          right={align === Align.RIGHT ? '0' : null}
        >
          <LazyImage
            src="/images/fritz.png"
            webpSrc="/images/fritz.webp"
            alt="Fritz von Stein"
            width="100%"
            height="100%"
            objectFit="cover"
          />
        </ImageWrapper>
        <Box
          py="10"
          pr={align === Align.LEFT ? [16, 12] : ['28', null, '48']}
          pl={align === Align.RIGHT ? [16, 12] : ['28', null, '48']}
          backgroundColor={variant === 'dark' ? 'primary.0' : 'white'}
          borderRadius={
            align === Align.LEFT ? '0 145px 145px 0' : '145px 0 0 145px'
          }
          color={variant === 'dark' ? 'white' : 'black'}
        >
          {children}
        </Box>
      </TransformFlex>
    </Flex>
  );
};

type FlipType = {
  flip?: boolean;
};

const ImageWrapper = styled(Box)<FlipType>`
  transform: ${p => (p.flip ? 'scaleX(-1)' : '')};
`;

type TransformFlexProps = {
  transform?: string;
};

const TransformFlex = styled(Flex)<TransformFlexProps>`
  transform: ${p => p.transform && p.transform};
  transition: transform 0.5s ${p => p.theme.easing};
`;

export { Narrator };
