import React from 'react';
import { Box, Ratio, Flex } from '@3pc/layout-components-react';
import { Text, Link } from 'src/elements';
import styled from '@emotion/styled';
import { ArrowRight } from 'src/icons';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import CHAPTER from 'src/graphql/Chapter.graphql';
import {
  Chapter as ChapterData,
  ChapterVariables,
} from 'src/graphql/__generated__/Chapter';
const ChapterPreview = () => {
  const { slug } = useParams();
  const { data } = useQuery<ChapterData, ChapterVariables>(CHAPTER, {
    variables: { slug, chapterFields: true },
  });

  React.useEffect(() => {
    if (!data?.chapter) return;
    document.title = `${data.chapter.title} | Klassik Stiftung Weimar`;
  }, [data]);

  return (
    <Box backgroundColor="primary.0" minHeight="100vh">
      <Flex justifyContent="center" pt="12">
        <PortHole
          color="white"
          borderRadius="5"
          width={['250px', null, '300px', '400px']}
          height="100%"
        >
          <Ratio ratio={1}>
            {data?.chapter?.image?.links[0] ? (
              <Image
                src={data.chapter.image.links[0] || ''}
                alt={data.chapter.image.description.shortAbstract || ''}
              />
            ) : (
              <Box />
            )}
          </Ratio>

          {data?.chapter?.title && (
            <TitleBox width="100%" px="4">
              <ShadowText
                as="h1"
                size="large"
                fontWeight="600"
                textTransform="uppercase"
                textAlign="center"
              >
                {data.chapter.title}
              </ShadowText>
            </TitleBox>
          )}
        </PortHole>
      </Flex>
      {data?.chapter && (
        <Flex
          width="100%"
          mt={[9, null, 16, 12]}
          color="white"
          pb="7"
          flexDirection="column"
          alignItems="center"
        >
          <Box px="4">
            <Text fontWeight="600" size="small" textAlign="center">
              {data.chapter.subTitle}
            </Text>
          </Box>
          <Box
            px={[7, null, 0]}
            width={['100%', null, '435px', '600px']}
            mt={[8, null, 10]}
          >
            <Text fontSize={['xxsmall', null, null, 'medium']} capsize={false}>
              {data.chapter.previewText}
            </Text>
          </Box>
          <Flex justifyContent="center" mt={[5, null, 12]}>
            <Link href={`/chapter/${data.chapter.slug}`}>
              <Box p="5">
                <ArrowRight />
              </Box>
            </Link>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

const PortHole = styled(Box)`
  position: relative;
  overflow: hidden;
`;

const TitleBox = styled(Box)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const ShadowText = styled(Text)`
  text-shadow: ${p => p.theme.colors.black} 1px 1px 5px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.5);
`;

export default ChapterPreview;
