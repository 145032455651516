import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from '@apollo/client';
// import { __isProd__ } from 'src/utils/environment';
// import { createPersistedQueryLink } from 'apollo-link-persisted-queries';

const httpLink = createHttpLink({
  uri: process.env.Q8R_KSW_API,
});

let link = ApolloLink.from([httpLink]);
// if (__isProd__) {
//   // @ts-ignore
//   link = createPersistedQueryLink().concat(link);
// }

const cache = new InMemoryCache();

const client = new ApolloClient({
  link,
  cache,
});

export default client;
