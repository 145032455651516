import React from 'react';
import { Box, Flex } from '@3pc/layout-components-react';
import { useParams } from 'react-router-dom';
import { Text, Image, Link } from 'src/elements';
import { ArrowDown } from 'src/icons';
import Substory from './Substory';
import { useQuery } from '@apollo/client';
import CHAPTER from 'src/graphql/Chapter.graphql';
import { ButtonLink } from 'src/components';
import {
  Chapter as ChapterData,
  ChapterVariables,
} from 'src/graphql/__generated__/Chapter';
import styled from '@emotion/styled';

const Chapter = () => {
  const { slug } = useParams();
  const { data, loading, error } = useQuery<ChapterData, ChapterVariables>(
    CHAPTER,
    {
      variables: { slug, substoryFields: true, chapterFields: true },
    }
  );

  React.useEffect(() => {
    if (!data?.chapter) return;
    document.title = `${data.chapter.title} | Klassik Stiftung Weimar`;
  }, [data]);

  if (!data && error)
    return (
      <Flex
        width="100vw"
        height="100vh"
        alignItems="center"
        justifyContent="center"
        p={['10', null, '20', '30']}
      >
        <Text>
          Hoppla da ist wohl etwas schief gelaufen. Bitte versuchen sie es
          später erneut.
        </Text>
      </Flex>
    );
  if (!loading && data && !data?.chapter)
    return (
      <Flex
        width="100vw"
        height="100vh"
        alignItems="center"
        justifyContent="center"
        p={['10', null, '20', '30']}
      >
        <Text>Dieses Kapitel scheint nicht zu existieren.</Text>
      </Flex>
    );
  if (!data?.chapter) return null;
  return (
    <Box>
      <Box position="fixed" height="100vh" width="100vw" zIndex={-1}>
        {data.chapter.image && (
          <Image
            objectFit="cover"
            width="100%"
            height="100%"
            links={data.chapter.image.links}
            alt={data.chapter.image.description.shortAbstract || ''}
          />
        )}
        <GradientBox
          position="absolute"
          left="0"
          top="0"
          width="100%"
          height="100%"
          opacity="0.5"
        />
      </Box>
      <Flex
        pt={['48', null, '64', '88']}
        px={[10, null, 0]}
        color="white"
        flexDirection="column"
        alignItems="center"
      >
        <Box width={['100%', null, '800px']}>
          <Text
            as="h1"
            fontSize={['xlarge', null, 'xxlarge', 'xxxlarge']}
            textTransform="uppercase"
            textAlign="center"
            capsize={false}
          >
            {data.chapter.title}
          </Text>
        </Box>
        <Box pt="36" width={['100%', null, '435px', '600px']}>
          <Text fontSize={['small', null, null, 'large']} capsize={false}>
            {data.chapter.introText}
          </Text>
        </Box>
      </Flex>
      {data.chapter.subStories[0] && (
        <Connector
          mt="40"
          color="white"
          substoryId={data.chapter.subStories[0].id}
          title={data.chapter.subStories[0].title}
        />
      )}
      {/* substories */}
      <Box
        backgroundColor="primary.0"
        borderRadius={['50vw 50vw 0 0', null, null, '510px 510px 0 0']}
        pb="20"
      >
        {data.chapter.subStories.map((substory, i, substories) => {
          if (!substory) return null;
          const nextSubstory = substories[i + 1];
          return (
            <React.Fragment key={substory.id}>
              <Substory substory={substory} mt="40" />
              {nextSubstory && (
                <Connector
                  mt="40"
                  substoryId={nextSubstory.id}
                  title={nextSubstory.title}
                />
              )}
            </React.Fragment>
          );
        })}
        <Flex justifyContent="center" mt="20">
          <ButtonLink href="/map">
            <Text fontWeight="500">Zur Karte</Text>
          </ButtonLink>
        </Flex>
      </Box>
    </Box>
  );
};

const Connector = ({ substoryId, title, ...props }) => {
  return (
    <Box {...props}>
      <Link href={`#substory-${substoryId}`}>
        <Flex
          flexDirection="column"
          alignItems="center"
          p="5"
          my={-5}
          width="100%"
          color="white"
        >
          <Text textAlign="center" size="medium" fontWeight="500">
            {title}
          </Text>
          <ArrowDown mt="12" />
        </Flex>
      </Link>
    </Box>
  );
};

const GradientBox = styled(Box)`
  background-image: linear-gradient(black 0%, black 40%, transparent 100%);
`;

export default Chapter;
