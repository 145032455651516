import React from 'react';
import { Box, Flex } from '@3pc/layout-components-react';
import { Text, Button } from 'src/elements';
import { optIn, optOut } from 'src/matomo';
import styled from '@emotion/styled';

const cookie = {
  name: 'KSW-Cookie',
  duration: 36500,
  trackings: [
    {
      insertElement: document.body,
      script:
        'var _paq = window._paq || [];\n' +
        '/* tracker methods like "setCustomDimension" should be called before "trackPageView" */\n' +
        "_paq.push(['setCookieDomain', '*.www.neue-natur.klassik-stiftung.de']);\n" +
        "_paq.push(['trackPageView']);\n" +
        "_paq.push(['enableLinkTracking']);\n" +
        '(function() {\n' +
        "var u='//matomo.klassik-stiftung.de/';\n" +
        "_paq.push(['setTrackerUrl', u+'matomo.php']);\n" +
        "_paq.push(['setSiteId', '22']);\n" +
        "var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];\n" +
        "g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);\n" +
        '})();',
      noscript:
        '<p><img src="//matomo.klassik-stiftung.de/matomo.php?idsite=22&amp;rec=1" style="border:0;" alt="" /></p>',
    },
  ],
};

function createCookie(cookieName, cookieValue, cookieDuration) {
  let expires = '';
  const days = cookieDuration;

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  } else {
    expires = '';
  }

  document.cookie = `${cookieName}=${cookieValue}${expires}; path=/; samesite=strict`;
}

function checkCookie(cookieName) {
  const nameEQ = `${cookieName}=`;
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function createTrackingScripts(trackings) {
  if (trackings.length) {
    trackings.forEach(tracking => {
      const insertElement = tracking.insertElement;

      if (tracking.script) {
        createScriptTag('script', tracking.script, insertElement);
      }

      if (tracking.noscript) {
        createScriptTag('noscript', tracking.noscript, insertElement);
      }
    });
  }
}

function createScriptTag(tagType, script, insertElement) {
  const newScript = document.createElement(tagType);
  const inlineScript = document.createTextNode(script);

  newScript.appendChild(inlineScript);
  insertElement.appendChild(newScript);
}

const CookieBanner = () => {
  const [consent, setConsent] = React.useState<string>('');

  const cookieExists = document.cookie.indexOf(cookie.name) > -1;

  React.useEffect(() => {
    if (!consent) return;
    const accepted = consent === 'true';
    createCookie(cookie.name, accepted, cookie.duration);
    if (accepted) {
      createTrackingScripts(cookie.trackings);
      optIn();
    } else optOut();
  }, [consent]);

  if (process.env.NODE_ENV !== 'production') {
    optOut();
    return null;
  }

  if (cookieExists) {
    if (checkCookie(cookie.name) !== 'false') {
      createTrackingScripts(cookie.trackings);
      optIn();
    } else optOut();
    return null;
  }

  return consent ? null : (
    <Box
      position="fixed"
      bottom="0"
      left="0"
      width="100vw"
      maxHeight="100vh"
      aria-modal="true"
      aria-labelledby="dialog_label"
      backgroundColor="primary.0"
      overflowY="auto"
      zIndex={4}
      color="white"
      p="10"
    >
      <Text as="h2">Unsere Cookie-Richtlinien</Text>
      <Box mt="5">
        <Text>
          Wir möchten MATOMO-Cookies setzen, um auf Basis Ihres
          Nutzungsverhaltens das Serviceangebot unserer Website zu verbessern.
          Dabei werden keine Informationen erhoben, die Sie persönlich
          identifizieren können. Mehr erfahren Sie <a href="/impressum">hier</a>
          .
        </Text>
      </Box>
      <Flex mt="10" flexDirection={['column', 'row']}>
        <Box>
          <Button variant="plain" onClick={() => setConsent('true')}>
            <PrimaryHoverFlex
              px="10"
              py="3"
              alignItems="center"
              justifyContent="center"
              color="white"
              border="2px solid"
              borderColor="white"
              borderRadius="50px"
            >
              <Text>Ich stimme zu</Text>
            </PrimaryHoverFlex>
          </Button>
        </Box>
        <Box ml={[0, 10]} mt={[5, 0]}>
          <Button variant="plain" onClick={() => setConsent('false')}>
            <SecondaryHoverFlex
              px="10"
              py="3"
              alignItems="center"
              justifyContent="center"
              color="white"
              border="2px solid"
              borderColor="transparent"
              borderRadius="50px"
            >
              <Text>Nur notwendige Cookies verwenden</Text>
            </SecondaryHoverFlex>
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

const PrimaryHoverFlex = styled(Flex)`
  &:hover {
    background-color: white;
    color: ${p => p.theme.colors.primary[0]};
  }
`;
const SecondaryHoverFlex = styled(Flex)`
  &:hover {
    border-color: white;
  }
`;

export { CookieBanner };
