import React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@emotion/react';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { color, typography, ColorProps, TypographyProps } from 'styled-system';
import useText, { TextSizeProps } from 'src/utils/use-text';

const Text = React.forwardRef<HTMLParagraphElement, TextProps>(
  (
    {
      children,
      as = 'p',
      size = 'medium',
      truncate = false,
      narrow = false,
      capsize = true,
      color,
      fontIndex = 0,
      textTransform,
      wordWrap,
      whiteSpace,
      ...props
    },
    ref
  ) => {
    const textStyles = useText(size, capsize, fontIndex);

    return (
      <StyledText
        color={color as any}
        as={as}
        size={size}
        truncate={truncate}
        narrow={narrow}
        textStyles={textStyles}
        fontIndex={fontIndex}
        textTransform={textTransform}
        wordWrap={wordWrap}
        whiteSpace={whiteSpace}
        {...props}
        ref={ref}
      >
        {truncate ? <TruncateText>{children}</TruncateText> : children}
      </StyledText>
    );
  }
);

export type TextProps = TypographyProps &
  ColorProps & {
    size?: TextSizeProps;
    truncate?: boolean;
    narrow?: boolean;
    capsize?: boolean;
    fontIndex?: 0 | 1;
    as?: As;
    children?: React.ReactNode;
    textTransform?:
      | 'none'
      | 'capitalize'
      | 'uppercase'
      | 'lowercase'
      | 'full-width'
      | 'full-size-kana';
    wordWrap?: 'normal' | 'break-word' | 'anywhere';
    whiteSpace?:
      | 'normal'
      | 'pre'
      | 'nowrap'
      | 'pre-wrap'
      | 'pre-line'
      | 'break-spaces';
  };

type As<P = any> = React.ElementType<P>;

const StyledText = styled('p', { shouldForwardProp })<StyledTextProps>(
  (p: StyledTextPropsWithTheme) => ({
    ...p.textStyles,
    letterSpacing: p.narrow ? '-0.025em' : 0,
    margin: 0,
    boxSizing: 'border-box',
    fontFamily: p.theme.fontFamily[p.fontIndex],
    textTransform: p.textTransform && p.textTransform,
    wordWrap: p.wordWrap && p.wordWrap,
  }),
  color,
  typography
);

type StyledTextProps = TextProps & {
  textStyles: any;
  color: any;
  fontIndex: number;
};
type StyledTextPropsWithTheme = StyledTextProps & {
  theme: Theme;
};

const TruncateText = styled.span`
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

Text.displayName = 'Text';
export { Text };
