import React from 'react';
import { Flex, Box, Columns, Column } from '@3pc/layout-components-react';
import { Text } from 'src/elements';
import { SubstoryFields_contentElements_objectElement as HistoricalObject } from 'src/graphql/__generated__/SubstoryFields';
import { CollapsiblePanel, CollapsibleButton } from 'src/components';
import styled from '@emotion/styled';
import { Plus } from 'src/icons';

const AssetInformation = ({
  historicalObject,
  ...props
}: {
  historicalObject: HistoricalObject;
  [x: string]: any;
}) => {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <Box width={['100%', null, 'auto']} px={[5, null, 0]} {...props}>
      <Flex
        flexDirection="column"
        alignItems={[null, null, 'center']}
        width={['100%', null, '495px', '680px']}
        backgroundColor={open ? 'primary.0' : 'transparent'}
      >
        <CollapsibleButton
          open={open}
          setOpen={setOpen}
          panelId={historicalObject.id}
        >
          <Flex
            width="100%"
            justifyContent="center"
            py="3"
            borderBottom="1px solid"
            borderColor="primary.0"
          >
            <Flex
              width={['100%', null, '435px', '600px']}
              px={[5, null, 0]}
              justifyContent="space-between"
              alignItems="center"
              color={open ? 'white' : 'inherit'}
            >
              <Text size="xsmall">Mehr Informationen</Text>
              <RotationBox isActive={open}>
                <Plus />
              </RotationBox>
            </Flex>
          </Flex>
        </CollapsibleButton>
        <CollapsiblePanel
          open={open}
          id={historicalObject.id}
          backgroundColor="primary.0"
          color="white"
        >
          <Box
            py={[7, null, 12]}
            px={[5, null, 0]}
            width={['100%', null, '435px', '600px']}
          >
            {historicalObject.description.shortAbstract && (
              <Text size="xsmall">
                {historicalObject.description.shortAbstract}
              </Text>
            )}
            <Box mt={[7, null, null, 12]}>
              <Columns collapseBelow={2} space={[4, null, null, 10]}>
                <Column>
                  {historicalObject.dating && (
                    <Box>
                      <Text
                        fontSize={['xsmall', null, null, 'small']}
                        capsize={false}
                        fontWeight="600"
                      >
                        Entstehungszeit
                      </Text>
                      <Text size="xsmall" capsize={false}>
                        {historicalObject.dating}
                      </Text>
                    </Box>
                  )}
                  {historicalObject.creationTechnique && (
                    <Box mt="4">
                      <Text
                        fontSize={['xsmall', null, null, 'small']}
                        capsize={false}
                        fontWeight="600"
                      >
                        Material Technik
                      </Text>
                      <Text size="xsmall" capsize={false}>
                        {historicalObject.creationTechnique}
                      </Text>
                      <Text size="xsmall" capsize={false}>
                        {historicalObject.material}
                      </Text>
                    </Box>
                  )}
                </Column>
                <Column>
                  {historicalObject.copyright && (
                    <Box mt="4">
                      <Text
                        fontSize={['xsmall', null, null, 'small']}
                        capsize={false}
                        fontWeight="600"
                      >
                        Copyright
                      </Text>
                      <Text size="xsmall" capsize={false}>
                        {historicalObject.copyright}
                      </Text>
                    </Box>
                  )}
                </Column>
              </Columns>
            </Box>
          </Box>
        </CollapsiblePanel>
      </Flex>
    </Box>
  );
};

type RotationBoxProps = {
  isActive?: boolean;
};

const RotationBox = styled(Box)<RotationBoxProps>`
  transform: ${p => p.isActive && 'rotate(135deg)'};
  transition: transform 0.25s ${p => p.theme.easing};
`;
export default AssetInformation;
