import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

const CustomLink = ({ href, ...restProps }) =>
  // NOTE: we only use react-router for relativ links
  href[0] === '/' ? (
    <ReactRouterLink to={href} {...restProps} />
  ) : (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a href={href} {...restProps} />
  );

export { CustomLink as Link };
